<template>
  <grid
    type="location"
    title="Groups"
    :multiple="true"
    :permanentFilters="{
      ...filters,
      company_id: $store.state.session.company.company_id
    }"
    :isMain="isMain"
    :visible="['location_name', 'location_time_created']"
  >
  </grid>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  name: 'LocationsBody',
  mixins: [BodyMixin],
  components: {
    Grid
  }
}
</script>
