<template>
  <Page full>
    <Locations
      :filters="{
        location_is_location: 0
      }"
    />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import Locations from '../bodies/Locations.vue'

export default {
  mixins: [PageMixin],
  name: 'LocationsPage',
  components: {
    Locations
  }
}
</script>
